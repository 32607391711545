import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const BarGraph = ({ data }) => {
  return (
    <BarChart
      width={900}
      height={200}
      data={data}
      margin={{ top: 10, right: 30, left: 20, bottom: 15 }}
      barGap={0}
      style={{ padding: '0px' }}
      barCategoryGap={0}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="time" tick={{ fontSize: 12 }} />
      <YAxis tick={{ fontSize: 12 }} domain={[0,100]}/>
      <Tooltip />
      {/* <Legend layout="vertical" verticalAlign="top" align="left" /> */}
      <Bar name="四週常模" dataKey="FourWeekNorm" fill="#90a3e0" />
    </BarChart>
  );
};

export default BarGraph;
