import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const BarGraphAppliance = ({ data }) => {
  return (
    <BarChart
      width={900}
      height={200}
      data={data}
      margin={{ top: 10, right: 30, left: 20, bottom: 15 }}
      barGap={0}
      style={{ padding: '0px' }}
      barCategoryGap={0}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="time" tick={() => null} />
      <YAxis tick={{fontSize:13}}/>
      <Tooltip />
      {/* <Legend verticalAlign="top" align="center" /> */}
      {/* <Legend  /> */}
           <Bar name="電器使用" dataKey="appliance_usage" fill="#80bcc1" />
    </BarChart>
  );
};

export default BarGraphAppliance;
