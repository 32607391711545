import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import DashBoardPage from './pages/dashboard';
import { Suspense } from 'react';
import {Route,Routes,BrowserRouter} from 'react-router-dom';
import PrivateRoutes from './ProtectedRoutes';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
      <Routes>
              <Route path="/"index element={<App />} />
              <Route element={<PrivateRoutes/>}>
              <Route path="dashboard" element={<DashBoardPage />} />
              </Route>
      </Routes>
      </Suspense>
      </BrowserRouter> 
   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
