import './dashboard.css';
import React, {useEffect, useState} from 'react';
import Row from '../widget/row';
import moment from "moment";
import leftAr from "../assets/arrow-left.png";
import calendar from "../assets/calendar.png";

import rightAr from "../assets/arrow-right.png";
import styled from "styled-components";
import {Button, IconButton, Paper, Tooltip} from "@material-ui/core";
import instance from '../api';
import face1 from "../assets/level1.png";
import face2 from "../assets/level2.png";
import face3 from "../assets/level3.png";
import face4 from "../assets/level4.png";
import BtnRound from "../widget/btn_round";
import {AiOutlineLogout} from "react-icons/ai"
import BarGraph from "../widget/bar_chart"
import { ToastContainer, toast } from 'react-toastify';
import BarGraphAppliance from '../widget/bar_chart_appliance';
import "react-calendar/dist/Calendar.css";

import Calendar from "react-calendar";
// import { ReactComponent as CalendarIcon } from "../assets/calendar.png";
const DashBoardPage = () => {

    useEffect(() => {
        document.title = 'Dashboard';
      }, []);
    
      const [data1, setData1] = useState([]); // four week active score norm
      const [data2,setData2]=useState([]); // Appliance Usage
      let [date = moment(new Date()).add(-1, 'days').toDate(), setDate] = useState();
      const [showCalendar, setShowCalendar] = useState(false);
    
    
    let [data = [], setData] = useState();
    
      function getAPI () {
            // var testData="TestHome"
            // var device_id="Home_2002"
            var userData=JSON.parse(localStorage.getItem('user'));
            // console.log(userData);
            const dateObj = new Date(date);
            const year = dateObj.getFullYear();
            const month = String(dateObj.getMonth() + 1).padStart(2, "0");
            const day = String(dateObj.getDate()).padStart(2, "0");
            const formattedDate = year + month + day; // format: YYYYMMDD
            var date1=""+formattedDate;

            console.log(date1)
            var device_id=userData.device_Id;
            var token=userData.token
            instance.get('/get_electrical_data/'+device_id+'/'+date1,{
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
               
                }
              }).then((res) => {
                // console.log(res.data);
                var active_score=res.data.active_score.toFixed(2);
                var lower_active_score=res.data.lower_active_score.toFixed(2);
                var norm_active_score=res.data.norm_active_score.toFixed(2);
                var higher_active_score=res.data.higher_active_score.toFixed(2);
                var activescorecolor;
                
                if (res.data.active_score>res.data.higher_active_score){
                    activescorecolor=1;//green
                }
                else if((res.data.higher_active_score>res.data.active_score && res.data.active_score>res.data.norm_active_score)){
                    activescorecolor=2;//blue
                }
                else if((res.data.norm_active_score>res.data.active_score) && (res.data.active_score)>(res.data.lower_active_score)){
                    activescorecolor=3;//
                   
                }
                else if((res.data.active_score)<(res.data.lower_active_score)){
                    activescorecolor=4;
                }
                // console.log("Average:"+norm_active_score+"Active score:"+active_score+"Lower:"+lower_active_score);
                
                // console.log("Bool"+(norm_active_score>active_score) && ((res.data.active_score)>(res.data.lower_active_score)));

                // console.log("Active Score:"+activescorecolor);
                // console.log("Active Score:"+activescorecolor);
                // const { four_week_active_score } = res.data.four_week_active_score;
                const timeFormat = 'HH:mm';
                const startTime = moment().startOf('day').subtract(28, 'days');
                var i;
                for(i=0;i<res.data.four_week_active_score.length;i++){
                    res.data.four_week_active_score[i]=res.data.four_week_active_score[i].toFixed(3);
                }
                const data1 = res.data.four_week_active_score.map((FourWeekNorm, i) => ({
                  time: moment(startTime).add(15 * i, 'minutes').format(timeFormat),
                  FourWeekNorm,
                }));


                setData1(data1);
                var corrcoeff=res.data.correlation_coefficient;
                var corrcoeffcolor;
                if (corrcoeff>0.69){
                    //highly correlated- green
                    corrcoeffcolor=1;
                }
                else if(0.69>corrcoeff && corrcoeff>0.49){
                    //moderately correlated- blue
                    corrcoeffcolor=2;
                }
                else if(0.49>corrcoeff  && corrcoeff>0.29){
                    //lowly correlated- yellow
                    corrcoeffcolor=3;
                }
                else if(corrcoeff<0.29){
                    //<0.29 weak or no correlation pink 
                    corrcoeffcolor=4;
                }


                const timeFormat1 = 'HH:mm';
                const startTime1 = moment().startOf('day').subtract(28, 'days');
                const data2 = res.data.appliance_usage.map((appliance_usage, i) => ({
                  time: moment(startTime1).add(15 * i, 'minutes').format(timeFormat1),
                  appliance_usage,
                }));
                setData2(data2);
                // console.log(res.data.correlation_coefficient);
                var statuscolor;
                if(corrcoeffcolor>activescorecolor){
                    statuscolor=corrcoeffcolor
                }
                else{
                    statuscolor=activescorecolor
                }
                // console.log("Corr:"+corrcoeffcolor+"active:"+activescorecolor+"status:"+statuscolor)
                setData(data = [{
                    "date": "2023/03/08",
                    "Id": device_id,
                    "Status": statuscolor,
                    "UserInput": 1,
                    "VitalSign": -1,
                    "StatusColor": statuscolor,
                    "ActiveScoreColor": activescorecolor,
                    "CorrCoeffColor": corrcoeffcolor,
                    "WhizPad": -1,
                    "CorrelationCoeff":res.data.correlation_coefficient.toFixed(3),
                    // "FourWeekActiveScore":res.data.four_week_active_score,
                    // "ApplianceUsage":res.data.appliance_usage,
                    "ActiveScore": res.data.active_score.toFixed(2),
                    "Regularity": 3,
                    "VSlevels": { "bloodPressure_sys": 0, "bloodGlucose": 0 },
                    "rawVS": {
                        "bloodGlucoseBase": 0,
                        "bloodPressureBase": 0,
                        "bloodPressure_sys": 0,
                        "bloodGlucose": 0,
                    },
                    "WPlevels": {
                        "inbedtimeHour": 0,
                        "correlation": 0,
                        "sleeptimeHour": 0,
                        "sleepEfficiency": 0,
                        "pressuretimeHour": 0
                    },
                    "rawWP": {
                        "sleeptimeNorm": 0,
                        "inbedtimeHour": 0,
                        "correlation": 0,
                        "inbedtimeNorm": 0,
                        "sleepEfficiency": 0,
                        "pressuretimeHour": 0,
                        "sleeptimeHour": 0
                    },
                    "ASlevel": 2,
                    "rawAS": active_score,
                    "Regularitylevels": { "low": 1, "medium": 2, "high": 3 },
                    "rawRegularity": {
                        "low": lower_active_score,
                        "medium": norm_active_score,
                        "high": higher_active_score
                    }
                }])
                
            }).catch((err) => {
                setData1([]);
                setData2([]);
                setData(data = [{
                "date": "2023/03/08",
                "Id": device_id,
                "Status": -1,
                "UserInput": 1,
                "VitalSign": -1,
                "StatusColor": -1,
                "ActiveScoreColor": -1,
                "CorrCoeffColor": -1,
                "WhizPad": -1,
                "CorrelationCoeff":0,
                // "FourWeekActiveScore":res.data.four_week_active_score,
                // "ApplianceUsage":res.data.appliance_usage,
                "ActiveScore": 0,
                "Regularity": 3,
                "VSlevels": { "bloodPressure_sys": 0, "bloodGlucose": 0 },
                "rawVS": {
                    "bloodGlucoseBase": 0,
                    "bloodPressureBase": 0,
                    "bloodPressure_sys": 0,
                    "bloodGlucose": 0,
                },
                "WPlevels": {
                    "inbedtimeHour": 0,
                    "correlation": 0,
                    "sleeptimeHour": 0,
                    "sleepEfficiency": 0,
                    "pressuretimeHour": 0
                },
                "rawWP": {
                    "sleeptimeNorm": 0,
                    "inbedtimeHour": 0,
                    "correlation": 0,
                    "inbedtimeNorm": 0,
                    "sleepEfficiency": 0,
                    "pressuretimeHour": 0,
                    "sleeptimeHour": 0
                },
                "ASlevel": 2,
                "rawAS": 0,
                "Regularitylevels": { "low": 1, "medium": 2, "high": 3 },
                "rawRegularity": {
                    "low": 0,
                    "medium": 0,
                    "high": 0   
                }
            }])
        });
;
              
            
            
        // console.log("myData", data);
    }
    
    useEffect(() => {
        getAPI();
      }, []);
      const handleDateChange = (date1) => {
        setDate(date = moment(date1).toDate());
        // console.log("calendar date: ", date1);
        // console.log("date:",date1)
        setData(data = []);
        getAPI();
        setShowCalendar(false);
      
      };
      const toggleCalendar = () => {
        setShowCalendar(!showCalendar);
        // getAPI();
      };
      
    function dateChange (dir) {
        if (dir === 0) {
            setDate(date = moment(date).add(-1, 'days').toDate());
        } else {
            setDate(date = moment(date).add(1, 'days').toDate());
        }
    }

   

   

    const dayStatus = (status,
                       UserInput,
                       VitalSign,
                       WhizPad,
                       ActiveScore,
                       Regularity,
                       VSlevels,
                       WPlevels,
                       ASlevel,
                       Regularitylevels,
                       rawVS,
                       rawWP,
                       rawAS,
                       rawRegularity,
                       CorrelationCoeff,
                       ActiveScoreColor,
                       CorrCoeffColor,
                       StatusColor) => {

        const displayColor = (num) => {
            if (num === -1) {
                return '#dddddd'
            } else if (num === 0) {
                return '#333333'
            } else if (num === 1) {
                return '#58a878' //green
            } else if (num === 2) {
                return '#5b9bd5'    //blue
            } else if (num === 3) {
                return '#f1c232' //yellow
            } else if (num === 4) {
                return '#f33039' //red
            } 
         
            else {
                return '#FFFFFF'
            }
        }

        const displayTTColor = (num) => {
            
            if (num === -1) {
                return '#dddddd'
            } else if (num === 0) {
                return '#333333'
            } else if (num === 1) {
                return '#58a878' //green
            } else if (num === 2) {
                return '#5b9bd5'    //blue
            } else if (num === 3) {
                return '#f1c232' //yellow
            } else if (num === 4) {
                return '#f33039' //red
            } 
         
            else {
                return '#FFFFFF'
            }
        }

        const displayText = (num) => {
            if (num === -1) {
                return '暫無紀錄'
            } else if (num === 0) {
                return '當日離床'
            } else if (num === 1) {
                return '狀況良好'
            } else if (num === 2) {
                return '大致正常'
            } else if (num === 3) {
                return '需要注意' // caution
            } else if (num === 4) {
                return '狀況異常' // abnormal
            }
            else if(num===7){
                return '需要注意'
            } 
            else if(num===8){
                return '狀況異常'
            }
            else {
                return ''
            }
        }

        const BtnColor = (num) => {
            // console.log(colorCode);
            if (num === -1) {
                return '#dddddd'
            } else if (num === 0) {
                return '#333333'
            } else if (num === 1) {
                return '#58a878' //green
            } else if (num === 2) {
                return '#5b9bd5'    //blue
            } else if (num === 3) {
                return '#f1c232' //yellow
            } else if (num === 4) {
                return '#f33039' //red
            } 
         
            else {
                return '#FFFFFF'
            }
        }

        const FaceIc = (faceCode) => {

            if (faceCode === 1) {
                return face1
            } else if (faceCode === 2) {
                return face2
            } else if (faceCode === 3) {
                return face3
            } else if (faceCode === 4) {
                return face4
            } else {
                return null
            }
        }

        return (
            <>
                
                <DashboardItem style={{justifyContent: 'center', paddingLeft:'20px',paddingRight:'10px'}}>
                    <BtnRound
                        width={`140px`}
                        btncolor={displayColor(status)}>
                        <div style={{color: '#ffffff'}} className="Padding">{displayText(status)}</div>
                    </BtnRound>
                </DashboardItem>
                <DashboardItem style={{justifyContent: 'center'}}>
                    <span>
                        <img className="UserIc" src={FaceIc(1)}/>
                    </span>
                </DashboardItem>
                
                                <DashboardItem style={{justifyContent: 'center',paddingRight:'0px'}}>
                    
                        <span>
                            <BtnRound
                                className="BtnText"
                                width={`50px`}
                                btncolor={BtnColor(VitalSign)}>
                                <div style={{color: '#ffffff'}}>VS</div>
                            </BtnRound>
                        </span>
                        </DashboardItem>
                        <DashboardItem style={{justifyContent: 'center', }}>
                        <span>
                            <BtnRound
                                className="BtnText"
                                width={`50px`}
                                btncolor={BtnColor(WhizPad)}>
                                <div style={{color: '#ffffff'}}>SS</div>
                            </BtnRound>
                        </span>
                    
                </DashboardItem>
                <div style={{paddingRight:'80px'}}></div>
                <DashboardItem style={{justifyContent: 'center',paddingRight:'20px',paddingLeft:'20px'}}>
                <div className="GroupedBtns">
                    <Tooltip
                        title={
                        <h2>
                            <div style={{color: displayTTColor(ActiveScoreColor)}}>
                            當日活躍分數:&nbsp;&nbsp;{rawAS}
                            <br />
                            <br />
                            </div>
                        </h2>
                        }
                    >
                        <span style={{paddingRight:'30px'}}>
                      
                       <BtnRound
                            className="BtnText"
                            width={`50px`}
                            style={{
                                backgroundColor: '#fff',
                                border: `3.5px solid ${
                                    console.log(ActiveScoreColor),
                                    displayTTColor(ActiveScoreColor)}`,
                                color: displayTTColor(ActiveScoreColor),
                            }}
                            >
                            <div style={{fontWeight:'bold'}}>AS</div>
                            </BtnRound>
                        </span>
                    </Tooltip>
                    <Tooltip
                        title={
                        <h2>
                            <div style={{color: displayTTColor(CorrCoeffColor)}}>
                            相關係數:&nbsp;&nbsp;{CorrelationCoeff}
                            <br />
                            <br />
                            </div>
                        </h2>
                        }
                    >
                        <span>
                        <BtnRound
                            className="BtnText"
                            width={`50px`}
                            style={{
                                backgroundColor: '#fff',
                                border: `3.5px solid ${displayTTColor(CorrCoeffColor)}`,
                                color: displayTTColor(CorrCoeffColor),
                            }}
                            >
                            <div style={{fontWeight:'bold'}}>RE</div>
                            </BtnRound>
                        </span>
                    </Tooltip>
                    </div>
                </DashboardItem>
            </>
        );
    }

    return (
        <>
            <div className="AppBar"  style={{display: 'flex', alignItems: 'center'}}    >
                    
                    <h3 className="AppBarTitle">健康狀態儀表板</h3>
                    <h3 className="AppBarSet"></h3>
                    <div className="AppBarSet">管理</div>
                    <span className="AppBarSet">設定</span>
                    <span className="AppBarSet"><a className="AppBarSet"
                                                   href="https://designer.mech.yzu.edu.tw/">語言</a></span>
                    <div style={{paddingLeft:'250px'}}>                            
                    <Button  title='Logout' style={{color:'white',backgroundColor: 'transparent', 
                                                    border: 'none', 
                                                    cursor: 'pointer',
                                                    outline: 'none',
                                                    paddingRight:'50px',
                                                    ':hover': {
                                                            opacity: 1
                                                             }
                                                    }} 
                    onClick={() => { 
                        localStorage.clear(); 
                        toast("Logging out",{position: toast.POSITION.TOP_CENTER, autoClose: 1500});
                        setTimeout(() => {
                            window.location.replace('/');
                        }, 1000);
                        
                        }}><AiOutlineLogout style={{ width: '30px', height: '30px' }}/>
                        
                    </Button>
                    <ToastContainer/>
                    </div>
                
            </div>
            <div className="Content">
                <DatePickerWrapper>
                    <SvgIconButton onClick={() => {
                        dateChange(0);
                        setData(data = []);
                        getAPI();
                    }}>
                        <img className="Icon" src={leftAr}/>
                    </SvgIconButton>
                    <span className="TextDesc" style={{marginLeft: `5px`, marginRight: `5px`}}>
                    <span className="TextNum">{date.getFullYear()}</span>&nbsp;{'/'}&nbsp;
                        <span className="TextNum">{date.getMonth() + 1}</span>&nbsp;{'/'}&nbsp;
                        <span className="TextNum">{date.getDate()}</span>&nbsp;{''}
                    </span>
                    <div>
                    <SvgIconButton style={{ marginRight: `10px` }} onClick={toggleCalendar}>
                        <img className="Icon" src={calendar} />
                    </SvgIconButton>
                    {showCalendar && (
                        
                        <div style={{ 
                                position: "absolute", 
                            top: "calc(50% - 250px)", 
                            left: "calc(50% - 200px)", 
                            width: "350px",
                            height: "100px",
                            padding: "20px", 
                            zIndex: 2,
                            borderRadius: "10px",
                          }}>
                            <Calendar 
                              onChange={handleDateChange} 
                              value={date} 
                              style={{ borderRadius: "10px", overflow: "hidden" }}
                            Id="calendar"
                            //   className={styles.react_calendar}
                             
                            //    tileClassName="calendar-tile"
                            />
                            <style>{`.react-calendar {
                                                        width: 100% !important;
                                                        direction:flex;!important;
                                                        max-width: 400px !important;
                                                        // height: 100% !important;
                                                        background-color: white !important;
                                                        border-radius: 10px !important;
                                                        box-shadow: 0px 0px 10px rgba(0,0,0,0.5) !important;
                                                        font-size: 1 rem !important;
                                                    }
                                                    .monday abbr {
                                                        font-weight: bold;
                                                        text-decoration: underline;
                                                    }
                                                    
                                                    .react-calendar__navigation {
                                                        // background-color: #6c5ce7 !important;
                                                        background:linear-gradient(to bottom right, #6c5ce7, #00b894);
                                                        color: white;
                                                        border-radius: 10px 10px 0 0 !important;
                                                        padding: 10px !important;
                                                        display: flex !important;
                                                        justify-content: space-between !important;
                                                        align-items: center !important;
                                                    }
                                                    
                                                    .react-calendar__navigation__label {
                                                        font-weight: bold !important;
                                                        font-size: 1.5rem !important;
                                                        text-align: center !important;
                                                    }
                                                    
                                                    .react-calendar__navigation button {
                                                        background-color: transparent !important;
                                                        border: none !important;
                                                        color: white !important;
                                                        font-size: 1.2rem !important;
                                                        cursor: pointer !important;
                                                    }
                                                    
                                                    .react-calendar__month-view__weekdays {
                                                        text-align: center !important;
                                                        text-transform: uppercase !important;
                                                        font-weight: bold !important;
                                                        font-size: 1rem !important; 
                                                        color: #666666 !important;
                                                    }
                                                    
                                                    .react-calendar__month-view__days {
                                                        text-align: center !important;
                                                    }
                                                    
                                                    .react-calendar__month-view__days__day--weekend {
                                                        color: #666666 !important;
                                                    }
                                                    
                                                    .react-calendar__tile--active {
                                                        background:linear-gradient(to bottom right, #6c5ce7, #00b894);
                                                        border-radius: 10px !important;
                                                        // background-color: #6c5ce7 !important;
                                                        color: white !important;
                                                    }
                                                    
                                                    .react-calendar__tile--now {
                                                        background-color: #f1c40f !important;
                                                    }
                                                    
                                                    .react-calendar__tile--rangeStart,
                                                    .react-calendar__tile--rangeEnd {
                                                        background-color: #6c5ce7 !important;
                                                        color: white !important;
                                                    }
                                                    
                                                    .react-calendar__tile--rangeStart:enabled:hover,
                                                    .react-calendar__tile--rangeEnd:enabled:hover,
                                                    .react-calendar__tile--rangeStart:enabled:focus,
                                                    .react-calendar__tile--rangeEnd:enabled:focus {
                                                        background-color: #6c5ce7 !important;
                                                        background:linear-gradient(to bottom right, #6c5ce7, #00b894);

                                                        color: white !important;
                                                    }
                                                    .react-calendar__navigation__label,
                                                    .react-calendar__month-view__weekdays,
                                                    .react-calendar__month-view__days__day {
                                                    font-size: 0.7rem !important;
                                                    }

                                                    `}
                            </style>
                          </div>
                        
                    )}
                    
                    </div>
                    <SvgIconButton onClick={() => {
                        dateChange(1);
                        setData(data = []);
                        getAPI();
                    }}>
                        <img className="Icon" src={rightAr}/>
                    </SvgIconButton>
                </DatePickerWrapper>
                <DashboardTitle>
                    <div style={{paddingLeft:'15px'}}>姓名</div>
                    <div style={{paddingRight:'15px'}}>狀態</div>
                    <div style={{paddingRight:'0px'}}>用戶自我評估</div>
                    {/* <div style={{ display: 'flex',paddingRight:'40px' }}> */}
                        <div style={{paddingLeft:'5px'}}>生理指標</div>
                        <div>睡眠狀態</div>
                    {/* </div> */}
                <div style={{paddingRight:'0px'}}></div>

                    <div style={{ display: 'flex',paddingRight:'40px'}}>
                        <div style={{paddingRight:'15px'}}>活躍度</div>
                        <div>規律性</div>
                    </div>
                    </DashboardTitle>

                {/*<DashBoardPage/>*/}
                {/**todo 資料格式*/}
                {data.map((item, index) => {
                    let {
                        Id,
                        Status,
                        UserInput,
                        VitalSign,
                        WhizPad,
                        ActiveScore,
                        Regularity,
                        VSlevels,
                        WPlevels,
                        ASlevel,
                        Regularitylevels,
                        rawVS,
                        rawWP,
                        rawAS,
                        rawRegularity,
                        CorrelationCoeff,
                        ApplianceUsage,
                        FourWeekActiveScore,
                        ActiveScoreColor,
                        StatusColor,
                        CorrCoeffColor
                    } = item;
                    return (
                       
                        <DashboardCard style={{justifyContent: 'flex-start',alignItems: 'flex-start'}}>
                            <Row height={'100%'} width={'100%'}>
                                <DashboardName>
                                    <div style={{paddingRight:'10px',paddingLeft:'20px'}}>{Id}</div>
                                </DashboardName>
                                {dayStatus(
                                    Status,
                                    UserInput,
                                    VitalSign,
                                    WhizPad,
                                    ActiveScore,
                                    Regularity,
                                    VSlevels,
                                    WPlevels,
                                    ASlevel,
                                    Regularitylevels,
                                    rawVS,
                                    rawWP,
                                    rawAS,
                                    rawRegularity,
                                    CorrelationCoeff,
                                    ActiveScoreColor,
                                    CorrCoeffColor,
                                    StatusColor
                                )}
                            </Row>
                            <Row style={{justifyContent:'flex-end'}}>
                            <div style={{paddingTop:'10px',display:'inline-block',paddingLeft:'30px',fontWeight:'bolder',fontSize:'16px',paddingRight:'470px'}}>電器使用</div>
                            <div style={{fontWeight:'600',fontSize:'14px',paddingTop:'10px',paddingRight:'20px'}}>活躍分數:{ActiveScore}
                            </div>
                            <div style={{paddingLeft:'5px',fontWeight:'600',fontSize:'14px',paddingTop:'10px',paddingRight:'50px'}}> 相關係數:{CorrelationCoeff}</div>
                            </Row>
                            <div style={{justifyContent:'center' ,width: '50%',}}>
                            <BarGraphAppliance data={data2} style={{justifyContent:'center'}}/>
                            </div>
                           
                            {/* <Row width='100%' height='auto' style={{}}> */}
                            <Row style={{justifyContent:'flex-end'}}>
                            <div style={{display:'inline-block',paddingLeft:'30px',fontWeight:'bolder',fontSize:'16px',paddingRight:'410px'}}>四週常模</div>
                            <div style={{display:'inline-block',fontSize:'14px',fontWeight:'600',paddingRight:'20px'}}>低標:{rawRegularity.low}</div>
                            <div style={{display:'inline-block',paddingLeft:'5px',fontSize:'14px',paddingRight:'20px',fontWeight:'600'}}>  常模分數:{rawRegularity.medium}</div>
                            <div style={{display:'inline-block',paddingLeft:'5px',fontSize:'14px',fontWeight:'600',paddingRight:'50px'}}>  高標:{rawRegularity.high}</div>
                            </Row>
                            <div style={{justifyContent:'center',}}>

                            <BarGraph data={data1} style={{justifyContent:'center'}}/>
                            </div>
                            {/* </Row> */}

                            {/* <Row width='100%' height='auto' style={{ paddingRight:'20px'}}> */}
                       

                            {/* </Row> */}
                        </DashboardCard>
                     
                    )
                })}
            </div>
        </>
    );
}
export default DashBoardPage;

const DatePickerWrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const SvgIconButton = styled(IconButton)`
  && {
    align-self: center;
    padding: 3px;
  }

  &:focus {
    outline: none;
  }
`;

const DashboardCard = styled(Paper)`
  width: 100%;
  height: 80px;
  margin-bottom: 20px;
  cursor: pointer;

  &&& {
    border-radius: 8px;
  }
`;

const DashboardTitle = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;

  div {
    flex: 1;
    font-weight: bold;
    font-size: 1.1rem;
  }
`;

const DashboardName = styled.div`
  flex: 1;
  display: flex;
  text-align: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;

  * {
    //padding-left: 30px;
  }

  div {
    font-weight: bold;
    font-size: 1.1rem;
  }

  p {
    margin: 2px 0 0 0;
    font-size: 0.95rem;
    color: #222222;
  }

  @media screen and (max-width: 768px) {
    * {
      padding-left: 10px;
    }
  }
`;

const DashboardItem = styled.div`
  flex: 1;
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  font-size: 1.1rem;

  span {
    font-weight: normal;
    font-size: 0.95rem;
    margin: 0 5px 0 5px;
  }

`;


